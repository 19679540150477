<template>
  <main>
    <router-view />
  </main>

</template>
<script>

export default {

  data() {
    return {

    }
  },
  methods: {

  },
}
</script>
<style>

</style>
