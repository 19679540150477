<template>
    <section class="copy">
        <div class="bg-primary">
            <div class="w-full h-full  px-p8 py-10">
                Error
            </div>
        </div>
    </section>
</template>
<script>

export default {

}
</script>

<style>

</style>